@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;

}
:root{
  --primary-color: #15385B;
 --secondary-color: #E5E7EB;
}
@media (max-width:500px){
  body{
    overflow-x: hidden;
  }
  img.faq__img {
    width: 277px !important;
    height: 284px !important;
    object-fit: cover;
    margin-top: 30px;
}
}
@media (min-width:700px) and (max-width:1000px){
  .products__bg__mobile {
    padding-bottom: 10px;
    padding-left: 100px;
}
}
